import React from "react";
import FormSparkForm from "./Form";
import "./contacts.scss"; // Import the SCSS file

const ContactUs = () => {
  return (
    <div id="contact-us-container">
      <h1>Contact Us</h1>
      <p>
        We would love to hear from you! If you have any questions, comments, or
        feedback about our services, please feel free to get in touch with us.
        You can contact us by phone, email, or by filling out the form below.
      </p>
      <div id="form-container">
        <FormSparkForm />
      </div>
    </div>
  );
};

export default ContactUs;
