import React from "react";
import "./about.scss";
import aboutus from "../../assets/images/about-us.png";
import aboutusr from "../../assets/images/about-us1.png";

const AboutPage = () => {
  return (
    <div className="aboutWrapper">
      <div className="aboutcol1">
        <h2>
          Transform your business with cutting <br /> edge AI solutions
        </h2>
        <p>
          Our platform is designed to make it easy for businesses to leverage{" "}
          <br />
          the power of machine learning to improve their operations, reduce{" "}
          costs, and increase revenue.
        </p>
      </div>
      <div className="aboutcol2">
        <div>
          <h2>Our Vision</h2>
          <p>
            Our vision is to empower businesses with the tools they need to
            drive innovation and success through AI. We believe in enabling
            organizations to harness the full potential of artificial
            intelligence by providing customizable solutions tailored to their
            specific needs. With our platform, businesses can take charge of
            model customization, leveraging their domain expertise to mold AI
            models that fit their unique business requirements.
          </p>
        </div>
        <div>
          <img src={aboutus} alt="About Us" />
        </div>
      </div>
      <div className="aboutcol3">
        <div>
          <img src={aboutusr} alt="About Us" />
        </div>
        <div>
          <h2>Our Mission</h2>
          <p>
            Our mission is to democratize AI adoption by providing accessible
            and customizable solutions that empower businesses of all sizes to
            integrate AI seamlessly into their operations. We are committed to
            enabling organizations to leverage their domain expertise to mold AI
            models that address their unique challenges and opportunities.
            Through our platform, businesses can drive innovation, improve
            efficiency, and unlock new avenues for growth, ultimately
            transforming the way they operate and compete in the digital age.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
