import React from "react";
import "./custommodel.scss";
import {
  CustomModelcol1Svg,
  CustomModelCOl2Svg,
  KeyFeaturesFourSvg,
  KeyFeaturesOneSvg,
  KeyFeaturesThreeSvg,
  KeyFeaturesTwoSvg,
} from "../../../assets/svg/CustomModelSvg";
import heroImage from "../../../assets/images/loan-default.png";
import { FourthHeroScreenSvg } from "../../../assets/svg/Homepagesvg";
import { KeyFeaturesFiveSvg } from "./../../../assets/svg/CustomModelSvg";

const CustomModel = () => {
  const handleAdminPlatformNavigate = () => {
    window.location.href = "https://admin.databridgeai.com/";
  };
  return (
    <div className="customWrapper">
      <div className="customcol1">
        <CustomModelcol1Svg />
        <h2>
          Seamlessly <span>Build </span> and
          <span> Deploy</span> ML Models with <br />
          Custom Model Curation
        </h2>
        <p>
          With CMC, businesses can effortlessly build, train, and <br />
          deploy customized ML models to production within minutes
        </p>
        <button onClick={handleAdminPlatformNavigate} className="try-it-now">
          Try it now
        </button>
      </div>
      <div className="customcol2">
        <div className="customcol2text">
          <h2>Build, Train and Deploy Curated Models</h2>
          <p>
            Our CMC solution offers an automated, self-service approach for
            creating ML models on-demand with a simple API call. No more waiting
            weeks for model development—streamline the process and achieve swift
            results. With our user-friendly interface, you control model
            customization. Just provide your data and objectives; our advanced
            algorithms handle the rest. In minutes, your tailored ML model will
            be ready for production.
          </p>
        </div>
        <div>
          <CustomModelCOl2Svg />
        </div>
      </div>
      <div className="customcol3">
        <h2>Benefits of using our Fraud Detection API</h2>
        <p>
          unlock the power of Data with Databridge AI - A leading B2B <br />
          SaaS Provider
        </p>
        <div className="customcol3spandiv">
          <span>
            <KeyFeaturesOneSvg />
            <h6>Cost-effective</h6>
            <p>
              Building a fraud detection system from scratch can be expensive,
              and our model offers a more affordable solution.
            </p>
          </span>
          <span>
            <KeyFeaturesTwoSvg />
            <h6>Time-saving</h6>
            <p>
              Developing a fraud detection system from scratch can be
              time-consuming. Our our model is already built and ready to use,
              which can save your business at least a month of development work.
            </p>
          </span>
          <span>
            <KeyFeaturesThreeSvg />
            <h6>Expertise and experience</h6>
            <p>
              Our team of experts has extensive knowledge and experience in
              fraud detection, ensuring that our model is reliable and accurate.
            </p>
          </span>
          <span>
            <KeyFeaturesFourSvg />
            <h6>Competitive advantage</h6>
            <p>
              Our model provides businesses with a competitive <br /> advantage
              in detecting and preventing fraudulent activities more quickly
            </p>
          </span>
          <span>
            <KeyFeaturesFiveSvg />
            <h6>Scalability</h6>
            <p>
              Our model can scale to meet the evolving needs of businesses and
              organizations, making it a flexible and adaptable solution.
            </p>
          </span>
        </div>
      </div>
      <div className="customcol4">
        <h2>Introducing Genie Databridge’s new Innovation</h2>
        <p>
          Your data whisperer. Chat, analyze, predict. Make data-driven
          decisions that win. Simple,
          <br /> powerful AI for business.
        </p>
        <button onClick={handleAdminPlatformNavigate} className="">
          Try For Free
        </button>
        <div>
          <span>
            <FourthHeroScreenSvg />
          </span>
        </div>
      </div>
    </div>
  );
};

export default CustomModel;
