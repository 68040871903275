import React from "react";
import { AiModelSvg } from "../../../assets/svg/AiModelSvg";
import "./aimodel.scss";

const AiModel = () => {
  const handleAdminPlatformNavigate = () => {
    window.location.href = "https://admin.databridgeai.com/";
  };
  return (
    <div className="Aiwrapper">
      <div className="aicol1">
        <AiModelSvg />
        <h2>Accelerate Your Business with Bespoke AI/ML Consultation</h2>
        <p>
          Enhance efficiency and speed up ML projects with our expert team. From
          idea to deployment, we ensure seamless integration and top
          performance. Transform your operations today!
        </p>
        <button onClick={handleAdminPlatformNavigate} className="try-it-now">
          Try it now
        </button>
      </div>
      <div className="aicol2">
        <h5>Build Faster, Scale Aggresively</h5>
        <div>
          <span>
            <h6>Expert Knowledge</h6>
            <p>
              Partnering with us provides significant benefits over building a
              solution in-house. Our experienced team brings extensive expertise
              and the latest industry best practices, saving you time and
              resources.
            </p>
          </span>
          <span>
            <h6>Competitive Advantage</h6>
            <p>
              ML models are crucial for maintaining a competitive edge. By
              collaborating with us, you ensure access to the latest and most
              effective techniques, driving innovation and staying ahead in your
              industry.
            </p>
          </span>
          <span>
            <h6>Development Support</h6>
            <p>
              Our experienced AI/ML consultants guide you through every step,
              from project scoping and algorithm selection to model building and
              testing.
            </p>
          </span>
        </div>
      </div>
    </div>
  );
};

export default AiModel;
