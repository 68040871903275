// curlCommands.js

export const curlCommands = {
  registerAccount: `curl --location --request POST 'https://api.databridgeai.com/auth/register' \
--header 'Content-Type: application/json' \
--data-raw '{
  "email": "test.company@email.com",
  "password": "test@123",
  "name": "Test Company"
}'`,

  loginAccount: `curl --location --request POST 'https://api.databridgeai.com/auth/login' \
--header 'Content-Type: application/json' \
--data-raw '{
  "email": "test.company@email.com",
  "password": "test@123"
}'`,

  createCustomModel: `curl --location --request POST 'https://api.databridgeai.com/custom-model/onboard' \
--header 'Authorization: Bearer <api_token>' \
--form 'file=@"<absolute_file_path>"' \
--form 'targetField="<your target field>"' \
--form 'title="<your file name>"' \
--form 'csvMetadata="<metadata optional>"'`,

  checkModelOnboardingStatus: `curl --location --request GET 'https://api.databridgeai.com/custom-model/onboard/<onboard_id>' \
--header 'Content-Type: application/json' \
--header 'Authorization: Bearer <api_token>'`,

  singlePrediction: `curl --location --request POST 'https://api.databridgeai.com/custom-model/' \
--header 'Content-Type: application/json' \
--data-raw '{
  "onboardId": "<onboardId>",
  "parameters": {
    "param1": "value1",
    "param2": "value2"
  }
}'`,

  batchPrediction: `curl --location --request POST 'https://api.databridgeai.com/custom-model/batch-prediction/' \
--header 'Content-Type: multipart/form-data' \
--form 'file=@"<absolute_file_path>"' \
--form 'onboardId="<onboardId>"'`,

  getApiToken: `curl --location --request GET 'https://api.databridgeai.com/auth/get-api-token' \
--header 'Content-Type: application/json'`,

  refreshToken: `curl --location --request GET 'https://api.databridgeai.com/auth/refresh-token' \
--header 'Content-Type: application/json'`,

  getModelData: `curl --location --request GET 'https://api.databridgeai.com/custom-model/onboard' \
--header 'Content-Type: application/json'`,

  getPredictionData: `curl --location --request GET 'https://api.databridgeai.com/billing/{id}/{params}' \
--header 'Content-Type: application/json'`,

  getBatchPredictionHistory: `curl --location --request GET 'https://api.databridgeai.com/custom-model/prediction/history?type=batch'`,

  getSinglePredictionHistory: `curl --location --request GET 'https://api.databridgeai.com/custom-model/prediction/history?type=single'`,
};
