import React from "react";
import "./loandefault.scss";
import heroImage from "../../../assets/images/loan-default.png";
import {
  LoanDefauktheroSvg,
  LoanFourSvg,
  LoanOneSvg,
  LoanThreeSvg,
  LoanTwoSvg,
} from "../../../assets/svg/LoanDefauktScg";

const LoanDefault = () => {
  const handleAdminPlatformNavigate = () => {
    window.location.href = "https://admin.databridgeai.com/";
  };
  return (
    <div className="loanWrapper">
      <div className="loancol1">
        <LoanDefauktheroSvg />
        <h2>
          Minimize Risk with Advanced Loan <br /> Default Models
        </h2>
        <p>
          Optimize lending with our predictive loan default API. <br /> Minimize
          bad debt and streamline risk assessment for informed decision-making
        </p>
        <button onClick={handleAdminPlatformNavigate} className="try-it-now">
          Try it now
        </button>
      </div>
      <div className="loancol2">
        <h2>
          Why use our <span>loan default</span> models
        </h2>
        <p>
          Using our loan default model provides significant benefits over <br />
          building a solution from scratch.
        </p>
        <div className="loancol2child">
          <div className="loancol2image">
            <img src={heroImage} alt="" width={"400px"} height={"600px"} />
          </div>
          <div className="loancol2text">
            <p>
              <LoanOneSvg />
              Using our loan default model <br />
              provides significant benefits over <br />
              building a solution from scratch
            </p>
            <p>
              <LoanTwoSvg />
              Our model is based on years of <br /> industry expertise and is{" "}
              <br />
              continuously updated with the <br />
              latest data and insights to ensure <br />
              its accuracy and effectiveness
            </p>
            <p>
              <LoanThreeSvg />
              By using our model, you can save <br />
              time and resources on developing <br />
              and maintaining an in-house <br /> solution
            </p>
            <p>
              <LoanFourSvg />
              While also benefiting from our <br /> team's experience and
              expertise <br /> in the field.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanDefault;
