import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Footer from "./layout/footer/Footer";
import Navbar from "./layout/navbar/Navbar";
import AboutPage from "./pages/about/About";
import Developers from "./pages/developers/Developers";
import Home from "./pages/home/Home";
import CustomModel from "./pages/productspages/custom-model-creation/CustomModel";
import FraudDetectionPage from "./pages/productspages/fraud-detection-pages/FraudDetectionPage";
import LoanDefault from "./pages/productspages/loan-default/LoanDefault";
import AiModel from "./pages/productspages/Ai and ML/AiModel";
import ContactUs from "./pages/contact-us/ContactUs";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/developers" element={<Developers />} />
          <Route path="/fraud-detection" element={<FraudDetectionPage />} />
          <Route path="/ai-ml" element={<AiModel />} />
          <Route path="/loan-default" element={<LoanDefault />} />
          <Route path="/AI-ML-Consultation" element={<Home />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/custom-model-creation" element={<CustomModel />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
