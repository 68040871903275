import React, { useState, useEffect, useRef } from "react";
import "./navbar.scss";
import { Link, useNavigate } from "react-router-dom";
import { NavBarSvg } from "../../assets/svg/DatbridgeSvg";

const Navbar = () => {
  const navigate = useNavigate();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const dropdownRef = useRef(null);
  const mobileMenuRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const toggleMobileMenu = () => {
    setMobileMenuVisible(!mobileMenuVisible);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
    if (
      mobileMenuRef.current &&
      !mobileMenuRef.current.contains(event.target)
    ) {
      setMobileMenuVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleHomeNavigate = () => {
    navigate("/");
  };

  const handleAdminPlatformNavigateNewTab = () => {
    window.open("https://admin.databridgeai.com/", "_blank");
  };

  const handleAdminPlatformNavigate = () => {
    window.location.href = "https://admin.databridgeai.com/";
  };

  const handleFAQNavigate = () => {
    // Navigate to the Footer section
    document.getElementById("footer").scrollIntoView({ behavior: "smooth" });
  };

  const closeDropdown = () => {
    setDropdownVisible(false);
  };

  return (
    <div className="navbar">
      <div onClick={handleHomeNavigate} className="logo">
        <NavBarSvg />
      </div>
      <div
        ref={mobileMenuRef}
        className={`links ${mobileMenuVisible ? "visible" : ""}`}
      >
        <ul>
          <li className="dropdown" ref={dropdownRef}>
            <span onClick={toggleDropdown}>
              Products
              <span className="arrow">&#9662;</span>
            </span>
            {dropdownVisible && (
              <div className="dropdown-menu">
                <Link to="/fraud-detection" onClick={closeDropdown}>
                  Fraud Detection
                </Link>
                <Link to="/loan-default" onClick={closeDropdown}>
                  Loan Default
                </Link>
                <Link to="/ai-ml" onClick={closeDropdown}>
                  AI/ML Consultation
                </Link>
                <Link to="/custom-model-creation" onClick={closeDropdown}>
                  Custom Model Creation
                </Link>
              </div>
            )}
          </li>
          <li>
            <Link to="/developers" onClick={closeDropdown}>
              Developers
            </Link>
          </li>
          <li>
            <Link to="/about" onClick={closeDropdown}>
              About-Us
            </Link>
          </li>
          <li>
            <Link to="/contact-us" onClick={closeDropdown}>
              Contact-Us
            </Link>
          </li>
          <li>
            <a onClick={handleFAQNavigate} href="#footer">
              FAQ
            </a>
          </li>
        </ul>
      </div>
      <div className="buttons">
        <button onClick={handleAdminPlatformNavigate} className="login">
          Login
        </button>
        <button
          onClick={handleAdminPlatformNavigateNewTab}
          className="get-started"
        >
          Get Started
        </button>
      </div>
      <div className="hamburger-menu" onClick={toggleMobileMenu}>
        &#9776;
      </div>
    </div>
  );
};

export default Navbar;
