import React from "react";
import {
  Card2Svg,
  Card3Svg,
  DashboardSvg,
  FifthHeroSvg,
  FifthSecondHeroSvg,
  FifthThirdHeroSvg,
  FourthHeroScreenSvg,
  NewDesignSvg,
  ThirdHeroActinableCardSvg,
  ThirdHeroApiTokensSvg,
  ThirdHeroMLSvg,
  Card1Svg,
} from "../../assets/svg/Homepagesvg";
import "./home.scss"; // Import the CSS file

const Home = () => {
  const handleAdminPlatformNavigate = () => {
    window.location.href = "https://admin.databridgeai.com/";
  };
  return (
    <div className="home">
      <div className="intro">
        <NewDesignSvg onCLick={handleAdminPlatformNavigate} />
        <h2>
          Transform your business with <br /> cutting-edge Ai solutions.
        </h2>
        <p>
          Unlock the power of Data with Databridge AI - A leading B2B <br />{" "}
          SaaS Provider
        </p>
        <button onClick={handleAdminPlatformNavigate} className="get-started">
          Get Started
        </button>
      </div>
      <div className="dashboard">
        <DashboardSvg />
      </div>

      <div className="secondhero">
        <h2>
          Experience building advance Artificial Intelligence models like never
          before
        </h2>
        <p>
          Our platform is designed to be flexible and scalable, meaning that it
          can be customized to meet the unique needs of
          <br /> your business. We offer a range of powerful tools and features
          that make it easy to build, train, and deploy machine
          <br /> learning models
        </p>

        <div className="secondherocards">
          <span>
            <Card1Svg />
          </span>
          <span>
            <Card2Svg />
          </span>
          <span>
            <Card3Svg />
          </span>
        </div>
      </div>
      <div className="thirdhero">
        <h2>Explore the unprecedented possibilities of Databridge AI</h2>
        <p>
          Unlock the power of Data with Databridge AI - A leading B2B SaaS
          Provider
        </p>

        <div className="thirdherosvg">
          <ThirdHeroMLSvg />
        </div>
        <div className="thirdHerobuttomcards">
          <span>
            <ThirdHeroActinableCardSvg />
          </span>
          <span>
            <ThirdHeroApiTokensSvg />
          </span>
        </div>
      </div>
      <div className="fourthero">
        <h2>Introducing Genie Databridge’s new Innovation</h2>
        <p>
          Your data whisperer. Chat, analyze, predict. Make data-driven
          decisions that win. Simple, powerful AI for business.
        </p>
        <button onClick={handleAdminPlatformNavigate} className="try-for-free">
          Try For Free
        </button>
        <div>
          <span>
            <FourthHeroScreenSvg />
          </span>
        </div>
        <div className="features">
          <span>
            <FifthHeroSvg />
            <h2>Unlocks Private Data Insights</h2>
            <p>
              Discover hidden trends and insights within your private data to
              make more informed decisions and drive better outcomes.
            </p>
          </span>
          <span>
            <FifthSecondHeroSvg />
            <h2>Deliver Instant Answers</h2>
            <p>
              Use our AI-driven platform to get immediate, accurate answers to
              your business queries.
            </p>
          </span>
          <span>
            <FifthThirdHeroSvg />
            <h2>Make Informed Decisions</h2>
            <p>
              Empower your team with data-driven insights to make strategic
              decisions quickly and confidently.
            </p>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Home;
