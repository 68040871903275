import React from "react";
import { DeveloperApiSvg } from "../../assets/svg/DeveloperSvg";
import CurlCard from "../../components/CurlCard";
import "./developer.scss";
import { curlCommands } from "./developerCurls";

const Developers = () => {
  const handleAdminPlatformNavigate = () => {
    window.location.href = "https://admin.databridgeai.com/";
  };

  return (
    <div className="devWrapper">
      <div className="devcol1">
        <DeveloperApiSvg />
        <h2>Innovate with Comprehensive Developer Resources</h2>
        <p>
          Explore the tools you need to build cutting-edge applications and
          seamless integrations. Our platform offers APIs, SDKs, and detailed
          documentation. Connect with our developer community and reach out for
          support. Let's innovate together!
        </p>
      </div>
      <div className="devcol2">
        <h2>DatabridgeAi Integration Instructions for Pre-Trained Models</h2>
        <p>
          Follow these step-by-step instructions to integrate our models with
          your services smoothly:
        </p>
        <div className="spandiv">
          <div className="spangroup">
            <span>
              <div>
                <p>1. Register an Account on Our Admin Platform</p>
              </div>
              <CurlCard sampleCurls={[curlCommands.registerAccount]} />
            </span>
            <span>
              <div>
                <p>2. Log In to Your Account on Our Admin Platform</p>
              </div>
              <CurlCard sampleCurls={[curlCommands.loginAccount]} />
            </span>
          </div>
          <div className="spangroup">
            <span>
              <div>
                <p>3. Create Your Custom Model</p>
              </div>
              <CurlCard sampleCurls={[curlCommands.createCustomModel]} />
            </span>
            <span>
              <div>
                <p>4. Check the Status of Your Model Onboarding</p>
              </div>
              <CurlCard
                sampleCurls={[curlCommands.checkModelOnboardingStatus]}
              />
            </span>
          </div>
          <div className="spangroup">
            <span>
              <div>
                <p>5. Perform Predictions with Your Custom Model</p>
              </div>
              <CurlCard
                sampleCurls={[
                  curlCommands.singlePrediction,
                  curlCommands.batchPrediction,
                ]}
              />
            </span>
            <span>
              <div>
                <p>10. Get Prediction History</p>
              </div>
              <CurlCard
                sampleCurls={[
                  curlCommands.getBatchPredictionHistory,
                  curlCommands.getSinglePredictionHistory,
                ]}
              />
            </span>
          </div>
          <div className="spangroup">
            <span>
              <div>
                <p>7. Refresh Your Token</p>
              </div>
              <CurlCard sampleCurls={[curlCommands.refreshToken]} />
            </span>
            <span>
              <div>
                <p>8. Retrieve Model Data</p>
              </div>
              <CurlCard sampleCurls={[curlCommands.getModelData]} />
            </span>
          </div>
          <div className="spangroup">
            <span>
              <div>
                <p>9. Retrieve Prediction Data for a Specific User and Month</p>
                <p>
                  Use this function to retrieve prediction data for a specific
                  user and month. Replace {"id"} with the user ID and {"params"}
                  with the month (or any other parameters).
                </p>
              </div>
              <CurlCard sampleCurls={[curlCommands.getPredictionData]} />
            </span>
            <span>
              <div>
                <p>6. Retrieve Your API Token</p>
              </div>
              <CurlCard sampleCurls={[curlCommands.getApiToken]} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Developers;
