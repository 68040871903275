import React from "react";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Card.css";

const CurlCard = ({ sampleCurls }) => {
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert("Copied to clipboard!");
    });
  };

  return (
    <div className="card">
      <div className="card-header">
        <div className="dots">
          <span className="dot red"></span>
          <span className="dot yellow"></span>
          <span className="dot green"></span>
        </div>
        <FontAwesomeIcon
          icon={faCopy}
          className="copy-icon"
          onClick={() => copyToClipboard(sampleCurls.join("\n"))}
        />
      </div>

      <div className="card-content">
        {sampleCurls?.map((curl, index) => (
          <div key={index} className="curl-command">
            <pre>{curl}</pre>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CurlCard;
