import React, { useEffect, useState } from "react";
import "./footer.scss";
import footerlogo from "../../assets/images/footer logo.png";
import axios from "axios";

const Footer = () => {
  const [email, setEmail] = useState("");
  const [openAnswer, setOpenAnswer] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const toggleAnswer = (id) => {
    console.log("clicked");
    setOpenAnswer((prevState) => (prevState === id ? null : id));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setMessage(""); // Clear previous message

    const formData = { email };

    try {
      await axios.post(`https://submit-form.com/1vblIYQ1`, formData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      setSuccess(true);
      setMessage("Suscribed");
      setEmail("");
    } catch (error) {
      console.error("Form submission error", error);
      setSuccess(false);
      setMessage("Subscription failed.");
    } finally {
      setLoading(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleScroll = () => {
    const scroll = window.scrollY;
    const footer = document.getElementById("footer");
    if (scroll > 100) {
      footer.classList.add("active");
    } else {
      footer.classList.remove("active");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const Year = new Date().getFullYear();
  return (
    <div className="footerWrapper" id="footer">
      <div className="footerCol1">
        <h2>Frequently asked questions</h2>
        <div className="faq-list">
          <div className="faq-item">
            <h6
              onClick={() => toggleAnswer(1)}
              className={openAnswer === 1 ? "open" : ""}
            >
              How can I get Started with Databridge?
            </h6>
            <p className={openAnswer === 1 ? "answer open" : "answer"}>
              To get started with Databridge, you can sign up for an account on
              our website and explore our range of products and services.
            </p>
          </div>
          <div className="faq-item">
            <h6
              onClick={() => toggleAnswer(2)}
              className={openAnswer === 2 ? "open" : ""}
            >
              What are the benefits of using Databridge?
            </h6>
            <p className={openAnswer === 2 ? "answer open" : "answer"}>
              Databridge offers advanced data analytics and machine learning
              solutions that help businesses make better decisions, improve
              efficiency, and drive growth.
            </p>
          </div>
          <div className="faq-item">
            <h6
              onClick={() => toggleAnswer(3)}
              className={openAnswer === 3 ? "open" : ""}
            >
              How do I contact Databridge support?
            </h6>
            <p className={openAnswer === 3 ? "answer open" : "answer"}>
              You can contact Databridge support by sending an email to
              info@databridge.com or by calling our customer service hotline at
            </p>
          </div>
        </div>
      </div>
      <div className="footerCol2">
        <div className="newsletter">
          <h2>Subscribe to our newsletter</h2>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button type="submit" disabled={isLoading}>
              {isLoading ? "Subscribing..." : "Subscribe"}
            </button>
          </form>
          {message && (
            <div className={isSuccess ? "message success" : "message error"}>
              {message}
            </div>
          )}
        </div>
        <div className="footerCol3">
          <div className="product">
            <h3>Product</h3>
            <a href="/fraud-detection">Fraud Detection</a>
            <a href="/loan-default">Loan Default</a>
            <a href="/custom-model-creation">Custom Model Creation</a>
            <a href="/ai-ml">AI/ML Consultation</a>
          </div>
          <div className="social">
            <h3>Socials</h3>
            <a href="https://www.instagram.com/databridgeai">Instagram</a>
            <a href="https://www.linkedin.com/company/databridgeai/about/">
              LinkedIn
            </a>
          </div>
          <div className="contact">
            <h3>Contact</h3>
            <p>Email: info@databridge.com</p>
            <p>Phone: </p>
          </div>
        </div>
        <div className="footerCol4">
          <div className="logo">
            <img src={footerlogo} alt="Databridge Logo" />
          </div>
          <div className="copyright">
            &copy; {Year} Databridge AI. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
