import React, { useState, useCallback } from "react";
import axios from "axios";

const FormSparkForm = ({}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isNameError, setNameError] = useState(false);
  const [isEmailError, setEmailError] = useState(false);
  const [isMessageError, setMessageError] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);

  const validateEmail = (email) => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(String(email).toLowerCase());
  };

  const validateForm = () => {
    let error = false;
    setNameError(false);
    setEmailError(false);
    setMessageError(false);

    if (!name) {
      setNameError(true);
      error = true;
    }

    if (!email || !validateEmail(email)) {
      setEmailError(true);
      error = true;
    }

    if (!message) {
      setMessageError(true);
      error = true;
    }

    return error;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (validateForm()) {
      setLoading(false);
      return;
    }

    const formData = { name, email, message };

    try {
      await axios.post(`https://submit-form.com/1vblIYQ1`, formData, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      setSuccess(true);
    } catch (error) {
      console.error("Form submission error", error);
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {isSuccess ? (
        <div>Success!</div>
      ) : (
        <div>
          <h6>Fill this form to contact us</h6>
          <p>Get to us easily by entering your details in this form</p>
          <input
            type="text"
            name="name"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{
              border: isNameError ? "1px solid red" : "1px solid black",
            }}
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{
              border: isEmailError ? "1px solid red" : "1px solid black",
            }}
          />
          <textarea
            name="message"
            placeholder="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            style={{
              border: isMessageError ? "1px solid red" : "1px solid black",
            }}
          />
          <button type="submit" disabled={isLoading}>
            {isLoading ? "Submitting..." : "Submit"}
          </button>
        </div>
      )}
    </form>
  );
};

export default FormSparkForm;
