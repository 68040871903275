import React from "react";
import "./fraudDetection.scss";
import { FourthHeroScreenSvg } from "../../../assets/svg/Homepagesvg";
import {
  CompetitiveSvg,
  CostEffectiveSvg,
  ExpertiseSvg,
  FraudDectetionCol1Svg,
  ScalabilitySvg,
  SecondHeroNumberfiveSvg,
  SecondHeroNumberfourSvg,
  SecondHeroNumberOneSvg,
  SecondHeroNumbersixSvg,
  SecondHeroNumberthreeSvg,
  SecondHeroNumbertwoSvg,
  TimeSavingSvg,
} from "../../../assets/svg/FraudDetectionSvg";
import heroImage from "../../../assets/images/loan-default.png";

const FraudDetectionPage = () => {
  const handleAdminPlatformNavigate = () => {
    window.location.href = "https://admin.databridgeai.com/";
  };
  return (
    <div className="fraudWrapper">
      <div className="fraudcol1">
        <FraudDectetionCol1Svg />
        <h2>
          Stay Ahead with Our <br />
          Cutting-Edge Fraud Detection API
        </h2>
        <p>
          Our fraud detection API uses advanced machine learning to <br />{" "}
          swiftly detect and prevent fraudulent activities.
        </p>
        <button onClick={handleAdminPlatformNavigate} className="try-it-now">
          Try it now
        </button>
      </div>
      <div className="fraudcol2">
        <h2>Experience Data Analysis like never before</h2>
        <p>
          Our platform is designed to be flexible and scalable, meaning that it
          can be customized to meet the unique needs of <br /> your business. We
          offer a range of powerful tools and features that make it easy to
          build, train, and deploy machine <br /> learning models
        </p>
        <div className="fraudcol2spandiv">
          <span>
            <SecondHeroNumberOneSvg />
            <h6>Real-time feedback</h6>
            <p>
              Our fraud detection system provides <br />
              real-time data-loop, enabling <br />
              businesses to quickly detect and <br />
              respond to potential threats as they occur.
            </p>
          </span>
          <span>
            <SecondHeroNumbertwoSvg />
            <h6>Machine learning Algorithms</h6>
            <p>
              Our model uses advanced machine learning <br />
              algorithms to analyze large volumes of data and <br />
              identify suspicious patterns and behaviors.
            </p>
          </span>
          <span>
            {" "}
            <SecondHeroNumberthreeSvg />
            <h6>Customizable responses</h6>
            <p>
              Our fraud detection model is highly customizable, allowing <br />
              businesses to tailor their rules and alerts to their <br />
              unique needs and risk profiles.
            </p>
          </span>
          <span>
            {" "}
            <SecondHeroNumberfourSvg />
            <h6>Integration with existing systems</h6>
            <p>
              Our solution is designed to integrate seamlessly with <br />{" "}
              existing systems, such as payment processing platforms or CRM{" "}
              <br />
              software, making it easy for businesses to incorporate fraud{" "}
              <br />
              detection into their workflows.
            </p>
          </span>
          <span>
            {" "}
            <SecondHeroNumberfiveSvg />
            <h6>Risk scoring</h6>
            <p>
              Our system includes risk scoring and prioritization <br />
              features, enabling businesses to focus on the most significant{" "}
              <br /> fraud risks and allocate resources accordingly.
            </p>
          </span>
          <span>
            {" "}
            <SecondHeroNumbersixSvg />
            <h6>Expert support</h6>
            <p>
              Our team of fraud detection specialists is available <br /> to
              provide expert support and guidance, helping businesses <br />
              optimize their fraud detection systems and respond effectively{" "}
              <br />
              to potential threats.
            </p>
          </span>
        </div>
      </div>
      <div className="fraudcol3">
        {" "}
        <h2>Benefits of using our Fraud Detection API</h2>
        <p>
          unlock the power of Data with Databridge AI - A leading B2B <br />{" "}
          SaaS Provider
        </p>
        <div className="fraudcol3spandiv">
          <span>
            <CostEffectiveSvg />
            <h6>Cost-effective</h6>
            <p>
              Building a fraud detection system from scratch can be expensive,
              and our model offers a more affordable solution.
            </p>
          </span>
          <span>
            {" "}
            <TimeSavingSvg />
            <h6>Time-saving</h6>
            <p>
              Developing a fraud detection system from scratch can be
              time-consuming. Our our model is already built and ready to use,
              which can save your business at least a month of development work.
            </p>
          </span>
          <span>
            {" "}
            <ExpertiseSvg />
            <h6>Expertise and experience</h6>
            <p>
              Our team of experts has extensive knowledge and experience in
              fraud detection, ensuring that our model is reliable and accurate.
            </p>
          </span>
          <span>
            {" "}
            <CompetitiveSvg />
            <h6>Competitive advantage</h6>
            <p>
              Our model provides businesses with a competitive advantage in
              detecting and preventing fraudulent activities more quickly
            </p>
          </span>
          <span>
            {" "}
            <ScalabilitySvg />
            <h6>Scalability</h6>
            <p>
              Our model can scale to meet the evolving needs of businesses and
              organizations, making it a flexible and adaptable solution.
            </p>
          </span>
        </div>
      </div>
      <div className="fraudcol4">
        <h2>Introducing Genie Databridge’s new Innovation</h2>
        <p>
          Your data whisperer. Chat, analyze, predict. Make data-driven
          decisions that win. Simple,
          <br /> powerful AI for business.
        </p>
        <button onClick={handleAdminPlatformNavigate} className="">
          Try For Free
        </button>
        <div>
          <span>
            <FourthHeroScreenSvg />
          </span>
        </div>
      </div>
    </div>
  );
};

export default FraudDetectionPage;
